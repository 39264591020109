
/* eslint-disable no-console */
import { defineComponent } from 'vue';
import PageHeader from '@/components/PageHeader.vue';
import BackBtn from '@/components/BackBtn.vue';

export default defineComponent({
  name: 'Contact',
  components: {
    PageHeader, BackBtn
  },
  beforeRouteEnter (to, from, next) {
    document.querySelector('html')?.classList.add('contact-page');
    next();
  },
  beforeRouteLeave (to, from, next) {
    document.querySelector('html')?.classList.remove('contact-page');
    next();
  }
});
